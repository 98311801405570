var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-heighg"},[_c('v-card',{attrs:{"dark":"","tile":"","color":"primary lighten-1"}},[_c('v-card-title',{staticClass:"justify-space-between"},[_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage title"}},[_vm._v(" Companies ")])]),_c('div',{staticClass:"ml-4"},[_c('v-btn',{attrs:{"small":"","light":"","rounded":"","dark":"","color":"secondary"},on:{"click":_vm.onCreateCompany}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" add ")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage create new company button"}},[_vm._v(" Create company ")])],1)],1)]),_c('v-card-subtitle',[_c('span',{attrs:{"translate-context":"MCompaniesPage subtitle"}},[_vm._v(" Manage companies ")])]),_c('v-card-text',[_c('div',{staticStyle:{"max-width":"400px"}},[_c('v-text-field',{attrs:{"label":_vm.searchForCompanyPlaceholder,"hide-details":"","rounded":"","dense":"","solo":"","light":"","clearable":"","prepend-inner-icon":_vm.isMobile ? null : 'search'},on:{"focus":function () { return _vm.focusOnSearch = true; },"blur":function () { return _vm.focusOnSearch = false; }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loadingData,"headers":_vm.companyTableHeaders,"items":_vm.companiesWithUsers,"search":_vm.search,"dense":"","show-expand":"","disable-pagination":"","hide-default-footer":"","sort-by":"name","single-expand":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var company = ref.item;
var isExpanded = ref.isExpanded;
return [_c('tr',{staticClass:"cursor-pointer",staticStyle:{"user-select":"none"},on:{"click":function () { return _vm.onCompanySelection(company, expand, isExpanded); }}},[_c('td',[_vm._v(" "+_vm._s(company.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(company.users.length)+" ")]),_c('td',[_c('v-icon',[_vm._v(" "+_vm._s(isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down')+" ")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var company = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"pa-4 backgroundAccent"},[_c('v-data-table',{staticClass:"relative",attrs:{"headers":_vm.userTableHeaders,"items":company.users,"dense":"","disable-pagination":"","hide-default-footer":"","sort-by":"fullName","fixed-header":"","height":"400","single-expand":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pa-4"},[_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","x-small":"","depressed":"","dark":""}},'v-btn',attrs,false),on),[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage attach existing user"}},[_vm._v(" Attach users ")]),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v(" group_add ")])],1)]}}],null,true),model:{value:(_vm.addUsersMenu),callback:function ($$v) {_vm.addUsersMenu=$$v},expression:"addUsersMenu"}},[_c('v-card',{attrs:{"width":"400"}},[_c('v-card-text',{staticClass:"backgroundAccent lighten-4 py-2"},[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption",attrs:{"translate-context":"MCompaniesPage menu for attaching users"}},[_vm._v(" Select users and click save to attach existing users to this company. ")])]),_c('v-virtual-scroll',{attrs:{"bench":15,"items":_vm.users.filter(function (u) { return !company.users.map(function (cu) { return cu.id; }).includes(u.id); }),"max-height":"400","item-height":"28"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var user = ref.item;
return [_c('v-list-item',{key:user.id,staticStyle:{"min-height":"0","height":"28px"},attrs:{"dense":""},on:{"click":function () { return _vm.onAddedUserSelection(user); }}},[_c('v-list-item-action',[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":_vm.addedUserIds.includes(user.id)}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(user.fullName)+" ")])],1)],1)]}}],null,true)}),(_vm.selectedUsers.length > 0)?_c('v-card-text',{staticClass:"backgroundAccent lighten-4 py-2",staticStyle:{"min-height":"48px"}},_vm._l((_vm.selectedUsers),function(user,i){return _c('v-chip',{key:i,staticClass:"my-1 mr-2",attrs:{"small":"","close":""},on:{"click:close":function () { return _vm.onAddedUserSelection(user); }}},[_vm._v(" "+_vm._s(user.fullName)+" ")])}),1):_vm._e(),_c('v-divider'),_c('v-card-actions',{staticClass:"backgroundAccent lighten-4 px-4 py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loadingData,"text":"","color":"error"},on:{"click":_vm.onCancelAddingUsersToCompany}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage cancel users to company button"}},[_vm._v(" Cancel ")])]),_c('v-btn',{staticClass:"px-6",attrs:{"disabled":_vm.loadingData,"loading":_vm.loadingData,"dark":!_vm.loadingData,"color":"secondary"},on:{"click":_vm.onSaveUsersToCompany}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage save users to company button"}},[_vm._v(" Save ")])])],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"error","x-small":"","outlined":"","dark":""}},'v-btn',attrs,false),on),[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage drop company users"}},[_vm._v(" Drop users ")]),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v(" person_remove ")])],1)]}}],null,true),model:{value:(_vm.dropUsersMenu),callback:function ($$v) {_vm.dropUsersMenu=$$v},expression:"dropUsersMenu"}},[_c('v-card',{attrs:{"width":"400"}},[_c('v-card-text',{staticClass:"backgroundAccent lighten-4 py-2"},[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption",attrs:{"translate-context":"MCompaniesPage menu for dropping users"}},[_vm._v(" Select users and click save to drop users from this company. ")])]),_c('v-virtual-scroll',{attrs:{"bench":15,"items":company.users,"max-height":"400","item-height":"28"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var user = ref.item;
return [_c('v-list-item',{key:user.id,staticStyle:{"min-height":"0","height":"28px"},attrs:{"dense":""},on:{"click":function () { return _vm.onRemovedUserSelection(user); }}},[_c('v-list-item-action',[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":_vm.droppedUserIds.includes(user.id)}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(user.fullName)+" ")])],1)],1)]}}],null,true)}),(_vm.droppedUsers.length > 0)?_c('v-card-text',{staticClass:"backgroundAccent lighten-4 py-2",staticStyle:{"min-height":"48px"}},_vm._l((_vm.droppedUsers),function(user,i){return _c('v-chip',{key:i,staticClass:"my-1 mr-2",attrs:{"small":"","close":""},on:{"click:close":function () { return _vm.onRemovedUserSelection(user); }}},[_vm._v(" "+_vm._s(user.fullName)+" ")])}),1):_vm._e(),_c('v-divider'),_c('v-card-actions',{staticClass:"backgroundAccent lighten-4 px-4 py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loadingData,"text":"","color":"error"},on:{"click":_vm.onCancelDroppingUsersFromCompany}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage cancel dropping users button"}},[_vm._v(" Cancel ")])]),_c('v-btn',{staticClass:"px-6",attrs:{"disabled":_vm.loadingData,"loading":_vm.loadingData,"dark":!_vm.loadingData,"color":"secondary"},on:{"click":_vm.onDropUsersFromCompany}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCompaniesPage drop users from company button"}},[_vm._v(" Save ")])])],1)],1)],1)],1)])]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }