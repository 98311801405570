<template>
  <div
    class="full-heighg"
  >
    <v-card
      dark
      tile
      color="primary lighten-1"
    >
      <v-card-title
        class="justify-space-between"
      >
        <div>
          <span
            v-translate
            translate-context="MCompaniesPage title"
          >
            Companies
          </span>
        </div>

        <div
          class="ml-4"
        >
          <v-btn
            small
            light
            rounded
            dark
            color="secondary"
            @click="onCreateCompany"
          >
            <v-icon
              small
              left
            >
              add
            </v-icon>

            <span
              v-translate
              translate-context="MCompaniesPage create new company button"
            >
              Create company
            </span>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-subtitle>
        <span
          translate-context="MCompaniesPage subtitle"
        >
          Manage companies
        </span>
      </v-card-subtitle>

      <v-card-text>
        <div
          style="max-width: 400px;"
        >
          <v-text-field
            v-model="search"
            :label="searchForCompanyPlaceholder"
            hide-details
            rounded
            dense
            solo
            light
            clearable
            :prepend-inner-icon="isMobile ? null : 'search'"
            @focus="() => focusOnSearch = true"
            @blur="() => focusOnSearch = false"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-data-table
        :loading="loadingData"
        :headers="companyTableHeaders"
        :items="companiesWithUsers"
        :search="search"
        dense
        show-expand
        disable-pagination
        hide-default-footer
        sort-by="name"
        single-expand
      >
        <template
          #item="{ expand, item: company, isExpanded }"
        >
          <tr
            class="cursor-pointer"
            style="user-select: none;"
            @click="() => onCompanySelection(company, expand, isExpanded)"
          >
            <td>
              {{ company.name }}
            </td>

            <td>
              {{ company.users.length }}
            </td>

            <td>
              <v-icon>
                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </td>
          </tr>
        </template>

        <template #expanded-item="{ headers, item: company }">
          <td :colspan="headers.length">
            <div
              class="pa-4 backgroundAccent"
            >
              <v-data-table
                :headers="userTableHeaders"
                :items="company.users"
                dense
                disable-pagination
                hide-default-footer
                sort-by="fullName"
                fixed-header
                height="400"
                single-expand
                class="relative"
              >
                <template #header>
                  <div
                    class="pa-4"
                  >
                    <div>
                      <v-menu
                        v-model="addUsersMenu"
                        :close-on-content-click="false"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="secondary"
                            x-small
                            depressed
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <div
                              v-translate
                              translate-context="MCompaniesPage attach existing user"
                            >
                              Attach users
                            </div>

                            <v-icon
                              right
                              x-small
                            >
                              group_add
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-card
                          width="400"
                        >
                          <v-card-text
                            class="backgroundAccent lighten-4 py-2"
                          >
                            <div
                              v-translate
                              translate-context="MCompaniesPage menu for attaching users"
                              class="caption"
                            >
                              Select users and click save to attach existing users to this company.
                            </div>
                          </v-card-text>

                          <v-virtual-scroll
                            :bench="15"
                            :items="
                              users.filter(u => !company.users.map(cu => cu.id).includes(u.id))
                            "
                            max-height="400"
                            item-height="28"
                          >
                            <template #default="{ item: user }">
                              <v-list-item
                                :key="user.id"
                                dense
                                style="min-height: 0; height: 28px;"
                                @click="() => onAddedUserSelection(user)"
                              >
                                <v-list-item-action>
                                  <v-checkbox
                                    class="ma-0 pa-0"
                                    :input-value="addedUserIds.includes(user.id)"
                                  />
                                </v-list-item-action>

                                <v-list-item-content
                                  class="pa-0"
                                >
                                  <v-list-item-title>
                                    {{ user.fullName }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-virtual-scroll>

                          <v-card-text
                            v-if="selectedUsers.length > 0"
                            class="backgroundAccent lighten-4 py-2"
                            style="min-height: 48px;"
                          >
                            <v-chip
                              v-for="(user, i) in selectedUsers"
                              :key="i"
                              small
                              close
                              class="my-1 mr-2"
                              @click:close="() => onAddedUserSelection(user)"
                            >
                              {{ user.fullName }}
                            </v-chip>
                          </v-card-text>

                          <v-divider />

                          <v-card-actions
                            class="backgroundAccent lighten-4 px-4 py-3"
                          >
                            <v-spacer />

                            <v-btn
                              :disabled="loadingData"
                              text
                              color="error"
                              class="mr-2"
                              @click="onCancelAddingUsersToCompany"
                            >
                              <span
                                v-translate
                                translate-context="MCompaniesPage cancel users to company button"
                              >
                                Cancel
                              </span>
                            </v-btn>

                            <v-btn
                              :disabled="loadingData"
                              :loading="loadingData"
                              :dark="!loadingData"
                              color="secondary"
                              class="px-6"
                              @click="onSaveUsersToCompany"
                            >
                              <span
                                v-translate
                                translate-context="MCompaniesPage save users to company button"
                              >
                                Save
                              </span>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>

                      <v-menu
                        v-model="dropUsersMenu"
                        :close-on-content-click="false"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="error"
                            x-small
                            outlined
                            dark
                            v-bind="attrs"
                            class="ml-4"
                            v-on="on"
                          >
                            <div
                              v-translate
                              translate-context="MCompaniesPage drop company users"
                            >
                              Drop users
                            </div>

                            <v-icon
                              right
                              x-small
                            >
                              person_remove
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-card
                          width="400"
                        >
                          <v-card-text
                            class="backgroundAccent lighten-4 py-2"
                          >
                            <div
                              v-translate
                              translate-context="MCompaniesPage menu for dropping users"
                              class="caption"
                            >
                              Select users and click save to drop users from this company.
                            </div>
                          </v-card-text>

                          <v-virtual-scroll
                            :bench="15"
                            :items="company.users"
                            max-height="400"
                            item-height="28"
                          >
                            <template #default="{ item: user }">
                              <v-list-item
                                :key="user.id"
                                dense
                                style="min-height: 0; height: 28px;"
                                @click="() => onRemovedUserSelection(user)"
                              >
                                <v-list-item-action>
                                  <v-checkbox
                                    class="ma-0 pa-0"
                                    :input-value="droppedUserIds.includes(user.id)"
                                  />
                                </v-list-item-action>

                                <v-list-item-content
                                  class="pa-0"
                                >
                                  <v-list-item-title>
                                    {{ user.fullName }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-virtual-scroll>

                          <v-card-text
                            v-if="droppedUsers.length > 0"
                            class="backgroundAccent lighten-4 py-2"
                            style="min-height: 48px;"
                          >
                            <v-chip
                              v-for="(user, i) in droppedUsers"
                              :key="i"
                              small
                              close
                              class="my-1 mr-2"
                              @click:close="() => onRemovedUserSelection(user)"
                            >
                              {{ user.fullName }}
                            </v-chip>
                          </v-card-text>

                          <v-divider />

                          <v-card-actions
                            class="backgroundAccent lighten-4 px-4 py-3"
                          >
                            <v-spacer />

                            <v-btn
                              :disabled="loadingData"
                              text
                              color="error"
                              class="mr-2"
                              @click="onCancelDroppingUsersFromCompany"
                            >
                              <span
                                v-translate
                                translate-context="MCompaniesPage cancel dropping users button"
                              >
                                Cancel
                              </span>
                            </v-btn>

                            <v-btn
                              :disabled="loadingData"
                              :loading="loadingData"
                              :dark="!loadingData"
                              color="secondary"
                              class="px-6"
                              @click="onDropUsersFromCompany"
                            >
                              <span
                                v-translate
                                translate-context="MCompaniesPage drop users from company button"
                              >
                                Save
                              </span>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import { translate } from 'vue-gettext';

  const { pgettext: $pgettext } = translate;

  export default {
    data: () => ({
      focusOnSearch: false,
      search: '',
      addedUserIds: [],
      droppedUserIds: [],
      addUsersMenu: false,
      dropUsersMenu: false,
      selectedCompany: null,
    }),

    computed: {
      ...mapGetters({
        isMobile: 'utils/isMobile',
        users: 'users/users',
        companiesWithUsers: 'companies/companiesWithUsers',
      }),

      companyTableHeaders() {
        return [
          {
            text: $pgettext('MCompaniesPage table header', 'Name'),
            value: 'name',
            width: 300,
            class: 'primary lighten-2 white--text',
          },
          {
            text: $pgettext('MCompaniesPage table header', 'User count'),
            sortable: false,
            class: 'primary lighten-2 white--text',
          },
          {
            text: $pgettext('MCompaniesPage table header', 'Open'),
            value: 'data-table-expand',
            class: 'primary lighten-2 white--text',
          },
        ];
      },

      userTableHeaders() {
        return [
          {
            text: $pgettext('MCompaniesPage user table header', 'Name'),
            value: 'fullName',
            width: 300,
          },
        ];
      },

      searchForCompanyPlaceholder() {
        return this.$pgettext('MCompaniesPage search companies - input placeholder', 'Search companies...');
      },

      loadingData() {
        return this.$wait.is(['loading companies', 'loading users']);
      },

      selectedUsers() {
        return this.users.filter(u => this.addedUserIds.includes(u.id));
      },

      droppedUsers() {
        return this.users.filter(u => this.droppedUserIds.includes(u.id));
      },
    },

    watch: {
      addUsersMenu(opened) {
        if (!opened) {
          this.addedUserIds = [];
        }
      },

      dropUsersMenu(opened) {
        if (!opened) {
          this.droppedUserIds = [];
        }
      },
    },

    mounted() {
      this.loadUsers();
    },

    methods: {
      ...mapActions({
        openDialog: 'dialog/openDialog',
      }),

      ...mapWaitingActions('companies', {
        addEmployees: 'adding employees',
        dropEmployees: 'dropping employees',
      }),

      ...mapWaitingActions('users', {
        loadUsers: 'loading users',
      }),

      onCreateCompany() {
        this.openDialog({
          dialogComponent: 'm-create-company-dialog',
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      onCompanySelection(company, expand, isExpanded) {
        this.selectedCompany = company;
        expand(!isExpanded);
      },

      onAddedUserSelection(user) {
        const i = this.addedUserIds.findIndex(userId => userId === user.id);

        if (i >= 0) {
          this.addedUserIds.splice(i, 1);
        } else {
          this.addedUserIds.push(user.id);
        }
      },

      onRemovedUserSelection(user) {
        const i = this.droppedUserIds.findIndex(userId => userId === user.id);

        if (i >= 0) {
          this.droppedUserIds.splice(i, 1);
        } else {
          this.droppedUserIds.push(user.id);
        }
      },

      async onSaveUsersToCompany() {
        const success = await this.addEmployees({
          companyId: this.selectedCompany.id,
          payload: {
            userIds: this.addedUserIds,
          },
        });

        // reload users to get fresh employment data
        if (success) {
          await this.loadUsers();
        }

        this.addUsersMenu = false;
      },

      onCancelAddingUsersToCompany() {
        this.addUsersMenu = false;
      },

      async onDropUsersFromCompany() {
        const success = await this.dropEmployees({
          companyId: this.selectedCompany.id,
          payload: {
            userIds: this.droppedUserIds,
          },
        });

        // reload users to get fresh employment data
        if (success) {
          await this.loadUsers();
        }

        this.dropUsersMenu = false;
      },

      onCancelDroppingUsersFromCompany() {
        this.dropUsersMenu = false;
      },
    },
  };
</script>
